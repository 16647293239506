@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap');

*,
*::after,
*::before{
	margin:0;
	padding:0;
	box-sizing: inherit;
}

a:link, a:visited{
	text-decoration: none;
	color:inherit;
}

body{
  	font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	line-height: 1.6;
	box-sizing: border-box;
}

.loader_wrapper{
	width:100%;
	height:100%;
	background-color: #fff;
	display: grid;
	place-content: center;
}

.loader_wrapper .loader{
	width: 200px;
	height: 200px;
}

.loader_wrapper .loader img{
	width: 100%;
	height: auto;
}

footer{
    transition: all 0.5s ease-in;
}

.pro-sidebar ~ main > footer{
    width: calc(100% - 270px);
    left:270px;
    transition: all 0.5s ease-in;
}

.pro-sidebar {
    z-index: 999 !important;
}

.pro-sidebar.collapsed ~ main > footer{
    width: calc(100% - 80px);
    left:80px;
    transition: all 0.5s ease-in;
}