.loader_wrapper{
	width:100%;
	height:100%;
	background-color: #fff;
	display: grid;
	place-content: center;
}

.loader_wrapper .loader{
	width: 200px;
	height: 200px;
}

.loader_wrapper .loader img{
	width: 100%;
	height: auto;
}