.header{
    padding: 10px;
    width: 100%;
    background-color: #62006e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}

.header_para{
    font-size: 1.5vw;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 .5vw;
    position: relative;
}

.logout_btn_wrapper{
    display: grid;
    place-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5vw;
}

.patient_list_wrapper{
    width: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
    padding: 10px;
}

ul.patient_list {
    width: calc(100% - 20px);
}

ul.patient_list.double {
    width: calc(50% - 20px);
}

ul.patient_list li{
    list-style-type: none;
    width: 100%;
    height: 2.5vw;
    border-bottom: 1px solid #f2f2f2;

    display: grid;
    grid-template-columns: 20% 35% 25% 20%;
    justify-content: center;
    align-items: center;
}

ul.patient_list li:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

ul.patient_list li:not(:last-child){
    margin-bottom: 5px;
}

ul.patient_list li span{
    display: grid;
    justify-content: start;
    grid-auto-flow: column;
    grid-gap: 5px;
    align-items: center;
    padding-left: 10px;
    font-weight: 400;
    font-size: 1.1vw;
    text-transform: capitalize;
}

ul.patient_list li.patient_list_header{
    font-weight: 600;
    background-color: #03173e;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

ul.patient_list li span.sl_no{
    display: grid;
    justify-content: start;
    align-items: center;
    color: #fff;
    padding-left: 50px;  
    font-size: 1.3vw;
    font-weight: 800; 
    position: relative;
}

ul.patient_list li span.sl_no_header{
    position: relative;
}

ul.patient_list li span.sl_no_header .arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #03173e;
    clip-path: polygon(52% 0, 91% 56%, 65% 50%, 65% 100%, 35% 100%, 35% 49%, 7% 55%);
    top: 0;
    left: 15px;
}

.up{
    -webkit-animation: slide-in-bottom 15s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5s infinite both;
	        animation: slide-in-bottom 15s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5s infinite both;
}

@-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(80vh);
              transform: translateY(80vh);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(40px);
              transform: translateY(40px);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(80vh);
              transform: translateY(80vh);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(40px);
              transform: translateY(40px);
      opacity: 1;
    }
  }
  

ul.patient_list li span.sl_no_wrapper{
    background-color: #060000;
    padding: 0 3px;
    border-radius: 3px;
    color: #fff;
    font-weight: 800; 
}

ul.patient_list li.Booked{
    background-color: #c51162;
    color: #fff;
}

ul.patient_list li.Confirm,
ul.patient_list li.Vaccine{
    background-color: #b8df42;
    color: #060000;
}

ul.patient_list li.Confirm span.sl_no,
ul.patient_list li.Vaccine span.sl_no{
    color: #060000;
    font-weight: 800; 
}

ul.patient_list li.Confirm span,
ul.patient_list li.Vaccine span{
    font-weight: 600;
}

ul.patient_list li span.text_center{
    justify-content: center;
}

ul.patient_list li.no_patients{
    height: auto;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    grid-gap: 50px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #c51162;
    padding-bottom: 50px;
}

ul.patient_list li.no_patients span{
    justify-content: center;
    padding-left: 0px;
}

.ads_screen_wrapper{
    position: fixed;
    inset: 0;
    z-index: 99999;
    background-color: #fff;
    opacity:0;
    visibility:hidden;
    transform:scale(1.2);
    transition: all 0.3s ease-out;
}

.ads_screen_wrapper.is_visible{
    opacity:1;
    visibility:visible;
    transform:scale(1);
}

.ads_screen_wrapper.is_visible .ad_wrapper{
    width: 100%;
    height: 100vh;
}

.ads_screen_wrapper.is_visible .ad_wrapper .image_ad{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.ads_screen_wrapper.is_visible .ad_wrapper .video_ad{
    width: 100%;
    height: auto;
    object-fit: cover;
}

