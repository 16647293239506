.login_wrapper{
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    background:url(../../assets/login-bg.jpg);
    background-size: cover;
    background-position: center;  
    display: grid;
    place-content: center;    
}

.login_form{
    width: 350px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);

}

.logo_wrapper{
    width: 300px;
    height: auto;
    margin-bottom: 50px;
}

.logo{
    width: 100%;
    height: auto;
}

.login_btn{
    margin-top: 30px;
}

.validation_error{
    width: 80%;
    font-size: 10px;
    color: red;
    text-align: left;
    margin-bottom: 10px;
}